export default {
    ROOT: [
        {
            icon: "el-icon-document",
            index: "/index",
            title: "首页",
        },
        {
            icon: "el-icon-document",
            index: "/matedata",
            title: "元数据",
            subs: [
                {
                    index: "/brand",
                    title: "手机型号",
                    subs: [
                        { index: "/PhoneBrand", title: "品牌" },
                        { index: "/PhoneType", title: "型号" },
                    ],
                },
                {
                    index: "/Stuff",
                    title: "材质数据",
                    subs: [
                        {
                            index: "/StuffType",
                            title: "材质分类",
                        },
                        {
                            index: "/StuffList",
                            title: "材质列表",
                        },
                    ],
                },
                {
                    index: "/materials",
                    title: "原材料管理",
                    subs: [
                        { index: "/MaterialType", title: "原材料类型" },
                        { index: "/MaterialList", title: "原材料列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-document",
            index: "/sysUser",
            title: "用户管理",
            subs: [
                { index: "/Role", title: "角色管理" },
                { index: "/User", title: "用户管理" },
            ],
        },
        {
            icon: "el-icon-goods",
            index: "/Product",
            title: "产品",
            subs: [
                { index: "/ProdModel", title: "产品模型" },
                // { index: '/deploy', title: '规格配置' },
                { index: "/ProdList", title: "产品列表" },
                {
                    index: "/spec",
                    title: "规格管理",
                    subs: [
                        { index: "/SpecList", title: "规格列表" },
                        { index: "/SpecInspect", title: "规格检阅" },
                    ],
                },
                { index: "/PhoneModel", title: "手机壳型号表" },
            ],
        },
        {
            icon: "el-icon-shopping-cart-full",
            index: "/select",
            title: "生产",
            subs: [
                { index: "/OrderList", title: "订单列表" },
                { index: "/filtration", title: "高级查询" },
                {
                    index: "/batch",
                    title: "批次管理",
                    subs: [
                        { index: "/allBatch", title: "所有批次" },
                        // { index: "/crustBatch", title: "待打壳批次" },
                        // { index: "/dispenseBatch", title: "待点胶批次" },
                        // { index: "/deliveryBatch", title: "待发货批次" },
                        { index: "/deliver", title: "发货" },
                    ],
                },
                { index: "/locker", title: "储物柜管理" },
                {
                    index: "/problem",
                    title: "问题件",
                    subs: [
                        { index: "/problemType", title: "问题件类型" },
                        { index: "/problem", title: "问题件列表" },
                    ],
                },
                {
                    index: "/aftermarket",
                    title: "售后",
                },
            ],
        },
        {
            icon: "el-icon-user",
            index: "/agent",
            title: "代理",
            subs: [
                { index: "/AgentType", title: "代理类型" },
                { index: "/AgentLevel", title: "代理等级" },
                { index: "/AgentManage", title: "代理管理" },
            ],
        },
        {
            icon: "el-icon-document",
            index: "/notice",
            title: "公告",
            subs: [
                { index: "/Notice", title: "公告列表" },
            ],
        },
        {
            icon: "el-icon-office-building",
            index: "/inventory",
            title: "仓库",
            subs: [
                { index: "/Supplier", title: "供应商管理" },
                { index: "/storeManage", title: "库存管理" },
                {
                    index: "/loss",
                    title: "损耗管理",
                    subs: [
                        {
                            index: "/LossType",
                            title: "损耗类型",
                        },
                        {
                            index: "/LossList",
                            title: "损耗列表",
                        },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-location",
            index: "/express",
            title: "快递",
            subs: [{ index: "/expressmanage", title: "快递商" }],
        },
        {
            icon: "el-icon-price-tag",
            index: "/finance",
            title: "财务",
            subs: [{ index: "/TotalStatist", title: "工厂统计" }, { index: "/FinanceTotal", title: "财务总览" }, { index: "/FinanceRecord", title: "代理记录" }, { index: "/TodayExpense", title: "消费详情" }],
        },
    ],
    DK_ADMIN: [
        {
            icon: "el-icon-document",
            index: "/index",
            title: "首页",
        },
        {
            icon: "el-icon-document",
            index: "/matedata",
            title: "元数据",
            subs: [
                {
                    index: "/brand",
                    title: "手机型号",
                    subs: [
                        { index: "/PhoneBrand", title: "品牌" },
                        { index: "/PhoneType", title: "型号" },
                    ],
                },
                {
                    index: "/Stuff",
                    title: "材质数据",
                    subs: [
                        {
                            index: "/StuffType",
                            title: "材质分类",
                        },
                        {
                            index: "/StuffList",
                            title: "材质列表",
                        },
                    ],
                },
                {
                    index: "/materials",
                    title: "原材料管理",
                    subs: [
                        { index: "/MaterialType", title: "原材料类型" },
                        { index: "/MaterialList", title: "原材料列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-document",
            index: "/sysUser",
            title: "用户管理",
            subs: [
                { index: "/Role", title: "角色管理" },
                { index: "/User", title: "用户管理" },
            ],
        },
        {
            icon: "el-icon-goods",
            index: "/Product",
            title: "产品",
            subs: [
                { index: "/ProdModel", title: "产品模型" },
                // { index: '/deploy', title: '规格配置' },
                { index: "/ProdList", title: "产品列表" },
                {
                    index: "/spec",
                    title: "规格管理",
                    subs: [
                        { index: "/SpecList", title: "规格列表" },
                        { index: "/SpecInspect", title: "规格检阅" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-shopping-cart-full",
            index: "/select",
            title: "生产",
            subs: [
                { index: "/filtration", title: "高级查询" },
                {
                    index: "/batch",
                    title: "批次管理",
                    subs: [
                        { index: "/allBatch", title: "所有批次" },
                        { index: "/crustBatch", title: "待打壳批次" },
                        { index: "/dispenseBatch", title: "待点胶批次" },
                        { index: "/deliveryBatch", title: "待发货批次" },
                        { index: "/myBatch", title: "我的批次" },
                    ],
                },
                { index: "/locker", title: "储物柜管理" },
                {
                    index: "/problem",
                    title: "问题件",
                    subs: [
                        { index: "/problemType", title: "问题件类型" },
                        { index: "/problem", title: "问题件列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-user",
            index: "/agent",
            title: "代理",
            subs: [
                { index: "/AgentType", title: "代理类型" },
                { index: "/AgentLevel", title: "代理等级" },
                { index: "/AgentManage", title: "代理管理" },
            ],
        },
        {
            icon: "el-icon-office-building",
            index: "/inventory",
            title: "仓库",
            subs: [
                { index: "/Supplier", title: "供应商管理" },
                { index: "/storeManage", title: "库存管理" },
                {
                    index: "/loss",
                    title: "损耗管理",
                    subs: [
                        {
                            index: "/LossType",
                            title: "损耗类型",
                        },
                        {
                            index: "/LossList",
                            title: "损耗列表",
                        },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-location",
            index: "/express",
            title: "快递",
            subs: [{ index: "/expressmanage", title: "快递商" }],
        },
        {
            icon: "el-icon-price-tag",
            index: "/finance",
            title: "财务",
            subs: [{ index: "/TotalStatist", title: "工厂统计" }, { index: "/FinanceTotal", title: "财务总览" }, { index: "/FinanceRecord", title: "代理记录" }, { index: "/TodayExpense", title: "消费详情" }],
        },
    ],
    PRINT_ASSIST: [
        {
            icon: "el-icon-document",
            index: "/index",
            title: "首页",
        },
        {
            icon: "el-icon-document",
            index: "/matedata",
            title: "元数据",
            subs: [
                {
                    index: "/brand",
                    title: "手机型号",
                    subs: [
                        { index: "/PhoneBrand", title: "品牌" },
                        { index: "/PhoneType", title: "型号" },
                    ],
                },
                {
                    index: "/Stuff",
                    title: "材质数据",
                    subs: [
                        {
                            index: "/StuffType",
                            title: "材质分类",
                        },
                        {
                            index: "/StuffList",
                            title: "材质列表",
                        },
                    ],
                },
                {
                    index: "/materials",
                    title: "原材料管理",
                    subs: [
                        { index: "/MaterialType", title: "原材料类型" },
                        { index: "/MaterialList", title: "原材料列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-document",
            index: "/sysUser",
            title: "用户管理",
            subs: [
                { index: "/Role", title: "角色管理" },
                { index: "/User", title: "用户管理" },
            ],
        },
        {
            icon: "el-icon-goods",
            index: "/Product",
            title: "产品",
            subs: [
                { index: "/ProdModel", title: "产品模型" },
                // { index: '/deploy', title: '规格配置' },
                { index: "/ProdList", title: "产品列表" },
                {
                    index: "/spec",
                    title: "规格管理",
                    subs: [
                        { index: "/SpecList", title: "规格列表" },
                        { index: "/SpecInspect", title: "规格检阅" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-shopping-cart-full",
            index: "/select",
            title: "生产",
            subs: [
                { index: "/filtration", title: "高级查询" },
                {
                    index: "/batch",
                    title: "批次管理",
                    subs: [
                        { index: "/allBatch", title: "所有批次" },
                        { index: "/crustBatch", title: "待打壳批次" },
                        { index: "/dispenseBatch", title: "待点胶批次" },
                        { index: "/deliveryBatch", title: "待发货批次" },
                        { index: "/myBatch", title: "我的批次" },
                    ],
                },
                { index: "/locker", title: "储物柜管理" },
                {
                    index: "/problem",
                    title: "问题件",
                    subs: [
                        { index: "/problemType", title: "问题件类型" },
                        { index: "/problem", title: "问题件列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-user",
            index: "/agent",
            title: "代理",
            subs: [
                { index: "/AgentType", title: "代理类型" },
                { index: "/AgentLevel", title: "代理等级" },
                { index: "/AgentManage", title: "代理管理" },
            ],
        },
        {
            icon: "el-icon-office-building",
            index: "/inventory",
            title: "仓库",
            subs: [
                { index: "/Supplier", title: "供应商管理" },
                { index: "/storeManage", title: "库存管理" },
                {
                    index: "/loss",
                    title: "损耗管理",
                    subs: [
                        {
                            index: "/LossType",
                            title: "损耗类型",
                        },
                        {
                            index: "/LossList",
                            title: "损耗列表",
                        },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-location",
            index: "/express",
            title: "快递",
            subs: [{ index: "/expressmanage", title: "快递商" }],
        },
        {
            icon: "el-icon-price-tag",
            index: "/finance",
            title: "财务",
            subs: [{ index: "/TotalStatist", title: "工厂统计" }, { index: "/FinanceTotal", title: "财务总览" }, { index: "/FinanceRecord", title: "代理记录" }, { index: "/TodayExpense", title: "消费详情" }],
        },
    ],
    SUPPLIER: [
        {
            icon: "el-icon-document",
            index: "/index",
            title: "首页",
        },
        {
            icon: "el-icon-document",
            index: "/matedata",
            title: "元数据",
            subs: [
                {
                    index: "/brand",
                    title: "手机型号",
                    subs: [
                        { index: "/PhoneBrand", title: "品牌" },
                        { index: "/PhoneType", title: "型号" },
                    ],
                },
                {
                    index: "/Stuff",
                    title: "材质数据",
                    subs: [
                        {
                            index: "/StuffType",
                            title: "材质分类",
                        },
                        {
                            index: "/StuffList",
                            title: "材质列表",
                        },
                    ],
                },
                {
                    index: "/materials",
                    title: "原材料管理",
                    subs: [
                        { index: "/MaterialType", title: "原材料类型" },
                        { index: "/MaterialList", title: "原材料列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-document",
            index: "/sysUser",
            title: "用户管理",
            subs: [
                { index: "/Role", title: "角色管理" },
                { index: "/User", title: "用户管理" },
            ],
        },
        {
            icon: "el-icon-goods",
            index: "/Product",
            title: "产品",
            subs: [
                { index: "/ProdModel", title: "产品模型" },
                // { index: '/deploy', title: '规格配置' },
                { index: "/ProdList", title: "产品列表" },
                {
                    index: "/spec",
                    title: "规格管理",
                    subs: [
                        { index: "/SpecList", title: "规格列表" },
                        { index: "/SpecInspect", title: "规格检阅" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-shopping-cart-full",
            index: "/select",
            title: "生产",
            subs: [
                { index: "/filtration", title: "高级查询" },
                {
                    index: "/batch",
                    title: "批次管理",
                    subs: [
                        { index: "/allBatch", title: "所有批次" },
                        { index: "/crustBatch", title: "待打壳批次" },
                        { index: "/dispenseBatch", title: "待点胶批次" },
                        { index: "/deliveryBatch", title: "待发货批次" },
                        { index: "/myBatch", title: "我的批次" },
                    ],
                },
                { index: "/locker", title: "储物柜管理" },
                {
                    index: "/problem",
                    title: "问题件",
                    subs: [
                        { index: "/problemType", title: "问题件类型" },
                        { index: "/problem", title: "问题件列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-user",
            index: "/agent",
            title: "代理",
            subs: [
                { index: "/AgentType", title: "代理类型" },
                { index: "/AgentLevel", title: "代理等级" },
                { index: "/AgentManage", title: "代理管理" },
            ],
        },
        {
            icon: "el-icon-office-building",
            index: "/inventory",
            title: "仓库",
            subs: [
                { index: "/Supplier", title: "供应商管理" },
                { index: "/storeManage", title: "库存管理" },
                {
                    index: "/loss",
                    title: "损耗管理",
                    subs: [
                        {
                            index: "/LossType",
                            title: "损耗类型",
                        },
                        {
                            index: "/LossList",
                            title: "损耗列表",
                        },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-location",
            index: "/express",
            title: "快递",
            subs: [{ index: "/expressmanage", title: "快递商" }],
        },
        {
            icon: "el-icon-price-tag",
            index: "/finance",
            title: "财务",
            subs: [{ index: "/TotalStatist", title: "工厂统计" }, { index: "/FinanceTotal", title: "财务总览" }, { index: "/FinanceRecord", title: "代理记录" }, { index: "/TodayExpense", title: "消费详情" }],
        },
    ],
    FINANCIAL: [
        {
            icon: "el-icon-document",
            index: "/index",
            title: "首页",
        },
        {
            icon: "el-icon-document",
            index: "/matedata",
            title: "元数据",
            subs: [
                {
                    index: "/brand",
                    title: "手机型号",
                    subs: [
                        { index: "/PhoneBrand", title: "品牌" },
                        { index: "/PhoneType", title: "型号" },
                    ],
                },
                {
                    index: "/Stuff",
                    title: "材质数据",
                    subs: [
                        {
                            index: "/StuffType",
                            title: "材质分类",
                        },
                        {
                            index: "/StuffList",
                            title: "材质列表",
                        },
                    ],
                },
                {
                    index: "/materials",
                    title: "原材料管理",
                    subs: [
                        { index: "/MaterialType", title: "原材料类型" },
                        { index: "/MaterialList", title: "原材料列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-document",
            index: "/sysUser",
            title: "用户管理",
            subs: [
                { index: "/Role", title: "角色管理" },
                { index: "/User", title: "用户管理" },
            ],
        },
        {
            icon: "el-icon-goods",
            index: "/Product",
            title: "产品",
            subs: [
                { index: "/ProdModel", title: "产品模型" },
                // { index: '/deploy', title: '规格配置' },
                { index: "/ProdList", title: "产品列表" },
                {
                    index: "/spec",
                    title: "规格管理",
                    subs: [
                        { index: "/SpecList", title: "规格列表" },
                        { index: "/SpecInspect", title: "规格检阅" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-shopping-cart-full",
            index: "/select",
            title: "生产",
            subs: [
                { index: "/filtration", title: "高级查询" },
                {
                    index: "/batch",
                    title: "批次管理",
                    subs: [
                        { index: "/allBatch", title: "所有批次" },
                        { index: "/crustBatch", title: "待打壳批次" },
                        { index: "/dispenseBatch", title: "待点胶批次" },
                        { index: "/deliveryBatch", title: "待发货批次" },
                        { index: "/myBatch", title: "我的批次" },
                    ],
                },
                { index: "/locker", title: "储物柜管理" },
                {
                    index: "/problem",
                    title: "问题件",
                    subs: [
                        { index: "/problemType", title: "问题件类型" },
                        { index: "/problem", title: "问题件列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-user",
            index: "/agent",
            title: "代理",
            subs: [
                { index: "/AgentType", title: "代理类型" },
                { index: "/AgentLevel", title: "代理等级" },
                { index: "/AgentManage", title: "代理管理" },
            ],
        },
        {
            icon: "el-icon-office-building",
            index: "/inventory",
            title: "仓库",
            subs: [
                { index: "/Supplier", title: "供应商管理" },
                { index: "/storeManage", title: "库存管理" },
                {
                    index: "/loss",
                    title: "损耗管理",
                    subs: [
                        {
                            index: "/LossType",
                            title: "损耗类型",
                        },
                        {
                            index: "/LossList",
                            title: "损耗列表",
                        },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-location",
            index: "/express",
            title: "快递",
            subs: [{ index: "/expressmanage", title: "快递商" }],
        },
        {
            icon: "el-icon-price-tag",
            index: "/finance",
            title: "财务",
            subs: [{ index: "/TotalStatist", title: "工厂统计" }, { index: "/FinanceTotal", title: "财务总览" }, { index: "/FinanceRecord", title: "代理记录" }, { index: "/TodayExpense", title: "消费详情" }],
        },
    ],
    FACTORY_ADMIN: [
        {
            icon: "el-icon-document",
            index: "/index",
            title: "首页",
        },
        {
            icon: "el-icon-document",
            index: "/matedata",
            title: "元数据",
            subs: [
                {
                    index: "/brand",
                    title: "手机型号",
                    subs: [
                        { index: "/PhoneBrand", title: "品牌" },
                        { index: "/PhoneType", title: "型号" },
                    ],
                },
                {
                    index: "/Stuff",
                    title: "材质数据",
                    subs: [
                        {
                            index: "/StuffType",
                            title: "材质分类",
                        },
                        {
                            index: "/StuffList",
                            title: "材质列表",
                        },
                    ],
                },
                {
                    index: "/materials",
                    title: "原材料管理",
                    subs: [
                        { index: "/MaterialType", title: "原材料类型" },
                        { index: "/MaterialList", title: "原材料列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-document",
            index: "/sysUser",
            title: "用户管理",
            subs: [
                { index: "/Role", title: "角色管理" },
                { index: "/User", title: "用户管理" },
            ],
        },
        {
            icon: "el-icon-goods",
            index: "/Product",
            title: "产品",
            subs: [
                { index: "/ProdModel", title: "产品模型" },
                // { index: '/deploy', title: '规格配置' },
                { index: "/ProdList", title: "产品列表" },
                {
                    index: "/spec",
                    title: "规格管理",
                    subs: [
                        { index: "/SpecList", title: "规格列表" },
                        { index: "/SpecInspect", title: "规格检阅" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-shopping-cart-full",
            index: "/select",
            title: "生产",
            subs: [
                { index: "/filtration", title: "高级查询" },
                {
                    index: "/batch",
                    title: "批次管理",
                    subs: [
                        { index: "/allBatch", title: "所有批次" },
                        { index: "/crustBatch", title: "待打壳批次" },
                        { index: "/dispenseBatch", title: "待点胶批次" },
                        { index: "/deliveryBatch", title: "待发货批次" },
                        { index: "/myBatch", title: "我的批次" },
                    ],
                },
                { index: "/locker", title: "储物柜管理" },
                {
                    index: "/problem",
                    title: "问题件",
                    subs: [
                        { index: "/problemType", title: "问题件类型" },
                        { index: "/problem", title: "问题件列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-user",
            index: "/agent",
            title: "代理",
            subs: [
                { index: "/AgentType", title: "代理类型" },
                { index: "/AgentLevel", title: "代理等级" },
                { index: "/AgentManage", title: "代理管理" },
            ],
        },
        {
            icon: "el-icon-office-building",
            index: "/inventory",
            title: "仓库",
            subs: [
                { index: "/Supplier", title: "供应商管理" },
                { index: "/storeManage", title: "库存管理" },
                {
                    index: "/loss",
                    title: "损耗管理",
                    subs: [
                        {
                            index: "/LossType",
                            title: "损耗类型",
                        },
                        {
                            index: "/LossList",
                            title: "损耗列表",
                        },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-location",
            index: "/express",
            title: "快递",
            subs: [{ index: "/expressmanage", title: "快递商" }],
        },
        {
            icon: "el-icon-price-tag",
            index: "/finance",
            title: "财务",
            subs: [{ index: "/TotalStatist", title: "工厂统计" }, { index: "/FinanceTotal", title: "财务总览" }, { index: "/FinanceRecord", title: "代理记录" }, { index: "/TodayExpense", title: "消费详情" }],
        },
    ],
    CK_ADMIN: [
        {
            icon: "el-icon-document",
            index: "/index",
            title: "首页",
        },
        {
            icon: "el-icon-document",
            index: "/matedata",
            title: "元数据",
            subs: [
                {
                    index: "/brand",
                    title: "手机型号",
                    subs: [
                        { index: "/PhoneBrand", title: "品牌" },
                        { index: "/PhoneType", title: "型号" },
                    ],
                },
                {
                    index: "/Stuff",
                    title: "材质数据",
                    subs: [
                        {
                            index: "/StuffType",
                            title: "材质分类",
                        },
                        {
                            index: "/StuffList",
                            title: "材质列表",
                        },
                    ],
                },
                {
                    index: "/materials",
                    title: "原材料管理",
                    subs: [
                        { index: "/MaterialType", title: "原材料类型" },
                        { index: "/MaterialList", title: "原材料列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-document",
            index: "/sysUser",
            title: "用户管理",
            subs: [
                { index: "/Role", title: "角色管理" },
                { index: "/User", title: "用户管理" },
            ],
        },
        {
            icon: "el-icon-goods",
            index: "/Product",
            title: "产品",
            subs: [
                { index: "/ProdModel", title: "产品模型" },
                // { index: '/deploy', title: '规格配置' },
                { index: "/ProdList", title: "产品列表" },
                {
                    index: "/spec",
                    title: "规格管理",
                    subs: [
                        { index: "/SpecList", title: "规格列表" },
                        { index: "/SpecInspect", title: "规格检阅" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-shopping-cart-full",
            index: "/select",
            title: "生产",
            subs: [
                { index: "/filtration", title: "高级查询" },
                {
                    index: "/batch",
                    title: "批次管理",
                    subs: [
                        { index: "/allBatch", title: "所有批次" },
                        { index: "/crustBatch", title: "待打壳批次" },
                        { index: "/dispenseBatch", title: "待点胶批次" },
                        { index: "/deliveryBatch", title: "待发货批次" },
                        { index: "/myBatch", title: "我的批次" },
                    ],
                },
                { index: "/locker", title: "储物柜管理" },
                {
                    index: "/problem",
                    title: "问题件",
                    subs: [
                        { index: "/problemType", title: "问题件类型" },
                        { index: "/problem", title: "问题件列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-user",
            index: "/agent",
            title: "代理",
            subs: [
                { index: "/AgentType", title: "代理类型" },
                { index: "/AgentLevel", title: "代理等级" },
                { index: "/AgentManage", title: "代理管理" },
            ],
        },
        {
            icon: "el-icon-office-building",
            index: "/inventory",
            title: "仓库",
            subs: [
                { index: "/Supplier", title: "供应商管理" },
                { index: "/storeManage", title: "库存管理" },
                {
                    index: "/loss",
                    title: "损耗管理",
                    subs: [
                        {
                            index: "/LossType",
                            title: "损耗类型",
                        },
                        {
                            index: "/LossList",
                            title: "损耗列表",
                        },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-location",
            index: "/express",
            title: "快递",
            subs: [{ index: "/expressmanage", title: "快递商" }],
        },
        {
            icon: "el-icon-price-tag",
            index: "/finance",
            title: "财务",
            subs: [{ index: "/TotalStatist", title: "工厂统计" }, { index: "/FinanceTotal", title: "财务总览" }, { index: "/FinanceRecord", title: "代理记录" }, { index: "/TodayExpense", title: "消费详情" }],
        },
    ],
    FH: [
        {
            icon: "el-icon-document",
            index: "/index",
            title: "首页",
        },
        {
            icon: "el-icon-document",
            index: "/matedata",
            title: "元数据",
            subs: [
                {
                    index: "/brand",
                    title: "手机型号",
                    subs: [
                        { index: "/PhoneBrand", title: "品牌" },
                        { index: "/PhoneType", title: "型号" },
                    ],
                },
                {
                    index: "/Stuff",
                    title: "材质数据",
                    subs: [
                        {
                            index: "/StuffType",
                            title: "材质分类",
                        },
                        {
                            index: "/StuffList",
                            title: "材质列表",
                        },
                    ],
                },
                {
                    index: "/materials",
                    title: "原材料管理",
                    subs: [
                        { index: "/MaterialType", title: "原材料类型" },
                        { index: "/MaterialList", title: "原材料列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-document",
            index: "/sysUser",
            title: "用户管理",
            subs: [
                { index: "/Role", title: "角色管理" },
                { index: "/User", title: "用户管理" },
            ],
        },
        {
            icon: "el-icon-goods",
            index: "/Product",
            title: "产品",
            subs: [
                { index: "/ProdModel", title: "产品模型" },
                // { index: '/deploy', title: '规格配置' },
                { index: "/ProdList", title: "产品列表" },
                {
                    index: "/spec",
                    title: "规格管理",
                    subs: [
                        { index: "/SpecList", title: "规格列表" },
                        { index: "/SpecInspect", title: "规格检阅" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-shopping-cart-full",
            index: "/select",
            title: "生产",
            subs: [
                { index: "/filtration", title: "高级查询" },
                {
                    index: "/batch",
                    title: "批次管理",
                    subs: [
                        { index: "/allBatch", title: "所有批次" },
                        { index: "/crustBatch", title: "待打壳批次" },
                        { index: "/dispenseBatch", title: "待点胶批次" },
                        { index: "/deliveryBatch", title: "待发货批次" },
                        { index: "/myBatch", title: "我的批次" },
                    ],
                },
                { index: "/locker", title: "储物柜管理" },
                {
                    index: "/problem",
                    title: "问题件",
                    subs: [
                        { index: "/problemType", title: "问题件类型" },
                        { index: "/problem", title: "问题件列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-user",
            index: "/agent",
            title: "代理",
            subs: [
                { index: "/AgentType", title: "代理类型" },
                { index: "/AgentLevel", title: "代理等级" },
                { index: "/AgentManage", title: "代理管理" },
            ],
        },
        {
            icon: "el-icon-office-building",
            index: "/inventory",
            title: "仓库",
            subs: [
                { index: "/Supplier", title: "供应商管理" },
                { index: "/storeManage", title: "库存管理" },
                {
                    index: "/loss",
                    title: "损耗管理",
                    subs: [
                        {
                            index: "/LossType",
                            title: "损耗类型",
                        },
                        {
                            index: "/LossList",
                            title: "损耗列表",
                        },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-location",
            index: "/express",
            title: "快递",
            subs: [{ index: "/expressmanage", title: "快递商" }],
        },
        {
            icon: "el-icon-price-tag",
            index: "/finance",
            title: "财务",
            subs: [{ index: "/TotalStatist", title: "工厂统计" }, { index: "/FinanceTotal", title: "财务总览" }, { index: "/FinanceRecord", title: "代理记录" }, { index: "/TodayExpense", title: "消费详情" }],
        },
    ],
    FH_ADMIN: [
        {
            icon: "el-icon-document",
            index: "/index",
            title: "首页",
        },
        {
            icon: "el-icon-document",
            index: "/matedata",
            title: "元数据",
            subs: [
                {
                    index: "/brand",
                    title: "手机型号",
                    subs: [
                        { index: "/PhoneBrand", title: "品牌" },
                        { index: "/PhoneType", title: "型号" },
                    ],
                },
                {
                    index: "/Stuff",
                    title: "材质数据",
                    subs: [
                        {
                            index: "/StuffType",
                            title: "材质分类",
                        },
                        {
                            index: "/StuffList",
                            title: "材质列表",
                        },
                    ],
                },
                {
                    index: "/materials",
                    title: "原材料管理",
                    subs: [
                        { index: "/MaterialType", title: "原材料类型" },
                        { index: "/MaterialList", title: "原材料列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-document",
            index: "/sysUser",
            title: "用户管理",
            subs: [
                { index: "/Role", title: "角色管理" },
                { index: "/User", title: "用户管理" },
            ],
        },
        {
            icon: "el-icon-goods",
            index: "/Product",
            title: "产品",
            subs: [
                { index: "/ProdModel", title: "产品模型" },
                // { index: '/deploy', title: '规格配置' },
                { index: "/ProdList", title: "产品列表" },
                {
                    index: "/spec",
                    title: "规格管理",
                    subs: [
                        { index: "/SpecList", title: "规格列表" },
                        { index: "/SpecInspect", title: "规格检阅" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-shopping-cart-full",
            index: "/select",
            title: "生产",
            subs: [
                { index: "/filtration", title: "高级查询" },
                {
                    index: "/batch",
                    title: "批次管理",
                    subs: [
                        { index: "/allBatch", title: "所有批次" },
                        { index: "/crustBatch", title: "待打壳批次" },
                        { index: "/dispenseBatch", title: "待点胶批次" },
                        { index: "/deliveryBatch", title: "待发货批次" },
                        { index: "/myBatch", title: "我的批次" },
                    ],
                },
                { index: "/locker", title: "储物柜管理" },
                {
                    index: "/problem",
                    title: "问题件",
                    subs: [
                        { index: "/problemType", title: "问题件类型" },
                        { index: "/problem", title: "问题件列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-user",
            index: "/agent",
            title: "代理",
            subs: [
                { index: "/AgentType", title: "代理类型" },
                { index: "/AgentLevel", title: "代理等级" },
                { index: "/AgentManage", title: "代理管理" },
            ],
        },
        {
            icon: "el-icon-office-building",
            index: "/inventory",
            title: "仓库",
            subs: [
                { index: "/Supplier", title: "供应商管理" },
                { index: "/storeManage", title: "库存管理" },
                {
                    index: "/loss",
                    title: "损耗管理",
                    subs: [
                        {
                            index: "/LossType",
                            title: "损耗类型",
                        },
                        {
                            index: "/LossList",
                            title: "损耗列表",
                        },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-location",
            index: "/express",
            title: "快递",
            subs: [{ index: "/expressmanage", title: "快递商" }],
        },
        {
            icon: "el-icon-price-tag",
            index: "/finance",
            title: "财务",
            subs: [{ index: "/TotalStatist", title: "工厂统计" }, { index: "/FinanceTotal", title: "财务总览" }, { index: "/FinanceRecord", title: "代理记录" }, { index: "/TodayExpense", title: "消费详情" }],
        },
    ],
    DJ: [
        {
            icon: "el-icon-document",
            index: "/index",
            title: "首页",
        },
        {
            icon: "el-icon-document",
            index: "/matedata",
            title: "元数据",
            subs: [
                {
                    index: "/brand",
                    title: "手机型号",
                    subs: [
                        { index: "/PhoneBrand", title: "品牌" },
                        { index: "/PhoneType", title: "型号" },
                    ],
                },
                {
                    index: "/Stuff",
                    title: "材质数据",
                    subs: [
                        {
                            index: "/StuffType",
                            title: "材质分类",
                        },
                        {
                            index: "/StuffList",
                            title: "材质列表",
                        },
                    ],
                },
                {
                    index: "/materials",
                    title: "原材料管理",
                    subs: [
                        { index: "/MaterialType", title: "原材料类型" },
                        { index: "/MaterialList", title: "原材料列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-document",
            index: "/sysUser",
            title: "用户管理",
            subs: [
                { index: "/Role", title: "角色管理" },
                { index: "/User", title: "用户管理" },
            ],
        },
        {
            icon: "el-icon-goods",
            index: "/Product",
            title: "产品",
            subs: [
                { index: "/ProdModel", title: "产品模型" },
                // { index: '/deploy', title: '规格配置' },
                { index: "/ProdList", title: "产品列表" },
                {
                    index: "/spec",
                    title: "规格管理",
                    subs: [
                        { index: "/SpecList", title: "规格列表" },
                        { index: "/SpecInspect", title: "规格检阅" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-shopping-cart-full",
            index: "/select",
            title: "生产",
            subs: [
                { index: "/filtration", title: "高级查询" },
                {
                    index: "/batch",
                    title: "批次管理",
                    subs: [
                        { index: "/allBatch", title: "所有批次" },
                        { index: "/crustBatch", title: "待打壳批次" },
                        { index: "/dispenseBatch", title: "待点胶批次" },
                        { index: "/deliveryBatch", title: "待发货批次" },
                        { index: "/myBatch", title: "我的批次" },
                    ],
                },
                { index: "/locker", title: "储物柜管理" },
                {
                    index: "/problem",
                    title: "问题件",
                    subs: [
                        { index: "/problemType", title: "问题件类型" },
                        { index: "/problem", title: "问题件列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-user",
            index: "/agent",
            title: "代理",
            subs: [
                { index: "/AgentType", title: "代理类型" },
                { index: "/AgentLevel", title: "代理等级" },
                { index: "/AgentManage", title: "代理管理" },
            ],
        },
        {
            icon: "el-icon-office-building",
            index: "/inventory",
            title: "仓库",
            subs: [
                { index: "/Supplier", title: "供应商管理" },
                { index: "/storeManage", title: "库存管理" },
                {
                    index: "/loss",
                    title: "损耗管理",
                    subs: [
                        {
                            index: "/LossType",
                            title: "损耗类型",
                        },
                        {
                            index: "/LossList",
                            title: "损耗列表",
                        },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-location",
            index: "/express",
            title: "快递",
            subs: [{ index: "/expressmanage", title: "快递商" }],
        },
        {
            icon: "el-icon-price-tag",
            index: "/finance",
            title: "财务",
            subs: [{ index: "/TotalStatist", title: "工厂统计" }, { index: "/FinanceTotal", title: "财务总览" }, { index: "/FinanceRecord", title: "代理记录" }, { index: "/TodayExpense", title: "消费详情" }],
        },
    ],
    DJ_ADMIN: [
        {
            icon: "el-icon-document",
            index: "/index",
            title: "首页",
        },
        {
            icon: "el-icon-document",
            index: "/matedata",
            title: "元数据",
            subs: [
                {
                    index: "/brand",
                    title: "手机型号",
                    subs: [
                        { index: "/PhoneBrand", title: "品牌" },
                        { index: "/PhoneType", title: "型号" },
                    ],
                },
                {
                    index: "/Stuff",
                    title: "材质数据",
                    subs: [
                        {
                            index: "/StuffType",
                            title: "材质分类",
                        },
                        {
                            index: "/StuffList",
                            title: "材质列表",
                        },
                    ],
                },
                {
                    index: "/materials",
                    title: "原材料管理",
                    subs: [
                        { index: "/MaterialType", title: "原材料类型" },
                        { index: "/MaterialList", title: "原材料列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-document",
            index: "/sysUser",
            title: "用户管理",
            subs: [
                { index: "/Role", title: "角色管理" },
                { index: "/User", title: "用户管理" },
            ],
        },
        {
            icon: "el-icon-goods",
            index: "/Product",
            title: "产品",
            subs: [
                { index: "/ProdModel", title: "产品模型" },
                // { index: '/deploy', title: '规格配置' },
                { index: "/ProdList", title: "产品列表" },
                {
                    index: "/spec",
                    title: "规格管理",
                    subs: [
                        { index: "/SpecList", title: "规格列表" },
                        { index: "/SpecInspect", title: "规格检阅" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-shopping-cart-full",
            index: "/select",
            title: "生产",
            subs: [
                { index: "/filtration", title: "高级查询" },
                {
                    index: "/batch",
                    title: "批次管理",
                    subs: [
                        { index: "/allBatch", title: "所有批次" },
                        { index: "/crustBatch", title: "待打壳批次" },
                        { index: "/dispenseBatch", title: "待点胶批次" },
                        { index: "/deliveryBatch", title: "待发货批次" },
                        { index: "/myBatch", title: "我的批次" },
                    ],
                },
                { index: "/locker", title: "储物柜管理" },
                {
                    index: "/problem",
                    title: "问题件",
                    subs: [
                        { index: "/problemType", title: "问题件类型" },
                        { index: "/problem", title: "问题件列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-user",
            index: "/agent",
            title: "代理",
            subs: [
                { index: "/AgentType", title: "代理类型" },
                { index: "/AgentLevel", title: "代理等级" },
                { index: "/AgentManage", title: "代理管理" },
            ],
        },
        {
            icon: "el-icon-office-building",
            index: "/inventory",
            title: "仓库",
            subs: [
                { index: "/Supplier", title: "供应商管理" },
                { index: "/storeManage", title: "库存管理" },
                {
                    index: "/loss",
                    title: "损耗管理",
                    subs: [
                        {
                            index: "/LossType",
                            title: "损耗类型",
                        },
                        {
                            index: "/LossList",
                            title: "损耗列表",
                        },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-location",
            index: "/express",
            title: "快递",
            subs: [{ index: "/expressmanage", title: "快递商" }],
        },
        {
            icon: "el-icon-price-tag",
            index: "/finance",
            title: "财务",
            subs: [{ index: "/TotalStatist", title: "工厂统计" }, { index: "/FinanceTotal", title: "财务总览" }, { index: "/FinanceRecord", title: "代理记录" }, { index: "/TodayExpense", title: "消费详情" }],
        },
    ],
    DK: [
        {
            icon: "el-icon-document",
            index: "/index",
            title: "首页",
        },
        {
            icon: "el-icon-document",
            index: "/matedata",
            title: "元数据",
            subs: [
                {
                    index: "/brand",
                    title: "手机型号",
                    subs: [
                        { index: "/PhoneBrand", title: "品牌" },
                        { index: "/PhoneType", title: "型号" },
                    ],
                },
                {
                    index: "/Stuff",
                    title: "材质数据",
                    subs: [
                        {
                            index: "/StuffType",
                            title: "材质分类",
                        },
                        {
                            index: "/StuffList",
                            title: "材质列表",
                        },
                    ],
                },
                {
                    index: "/materials",
                    title: "原材料管理",
                    subs: [
                        { index: "/MaterialType", title: "原材料类型" },
                        { index: "/MaterialList", title: "原材料列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-document",
            index: "/sysUser",
            title: "用户管理",
            subs: [
                { index: "/Role", title: "角色管理" },
                { index: "/User", title: "用户管理" },
            ],
        },
        {
            icon: "el-icon-goods",
            index: "/Product",
            title: "产品",
            subs: [
                { index: "/ProdModel", title: "产品模型" },
                // { index: '/deploy', title: '规格配置' },
                { index: "/ProdList", title: "产品列表" },
                {
                    index: "/spec",
                    title: "规格管理",
                    subs: [
                        { index: "/SpecList", title: "规格列表" },
                        { index: "/SpecInspect", title: "规格检阅" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-shopping-cart-full",
            index: "/select",
            title: "生产",
            subs: [
                { index: "/filtration", title: "高级查询" },
                {
                    index: "/batch",
                    title: "批次管理",
                    subs: [
                        { index: "/allBatch", title: "所有批次" },
                        { index: "/crustBatch", title: "待打壳批次" },
                        { index: "/dispenseBatch", title: "待点胶批次" },
                        { index: "/deliveryBatch", title: "待发货批次" },
                        { index: "/myBatch", title: "我的批次" },
                    ],
                },
                { index: "/locker", title: "储物柜管理" },
                {
                    index: "/problem",
                    title: "问题件",
                    subs: [
                        { index: "/problemType", title: "问题件类型" },
                        { index: "/problem", title: "问题件列表" },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-user",
            index: "/agent",
            title: "代理",
            subs: [
                { index: "/AgentType", title: "代理类型" },
                { index: "/AgentLevel", title: "代理等级" },
                { index: "/AgentManage", title: "代理管理" },
            ],
        },
        {
            icon: "el-icon-office-building",
            index: "/inventory",
            title: "仓库",
            subs: [
                { index: "/Supplier", title: "供应商管理" },
                { index: "/storeManage", title: "库存管理" },
                {
                    index: "/loss",
                    title: "损耗管理",
                    subs: [
                        {
                            index: "/LossType",
                            title: "损耗类型",
                        },
                        {
                            index: "/LossList",
                            title: "损耗列表",
                        },
                    ],
                },
            ],
        },
        {
            icon: "el-icon-location",
            index: "/express",
            title: "快递",
            subs: [{ index: "/expressmanage", title: "快递商" }],
        },
        {
            icon: "el-icon-price-tag",
            index: "/finance",
            title: "财务",
            subs: [{ index: "/TotalStatist", title: "工厂统计" }, { index: "/FinanceTotal", title: "财务总览" }, { index: "/FinanceRecord", title: "代理记录" }, { index: "/TodayExpense", title: "消费详情" }],
        },
    ]

}